export const testing = {
  ru: {
    translation: {
      'Центральная заводская лаборатория ОАО «Беларускабель»': `Центральная заводская лаборатория ОАО «Беларускабель» (далее ЦЗЛ) соответствует
                  критериям Национальной системы аккредитации Республики Беларусь и аккредитована на
                  соответствие требованиям ГОСТ ISO/IEC 17025-2019 «Общие требования к компетентности
                  испытательных и калибровочных лабораторий» (аттестат аккредитации BY/112 2.0981 от
                  28.05.1996, действ. до 05.03.2027).
                  <p></p>
                  <span style={{textTransform: 'uppercase'}}>
                    В ОБЛАСТИ АККРЕДИТАЦИИ ЦЗЛ СЛЕДУЮЩИЕ ИСПЫТАНИЯ:
                  </span>
                  <p></p>
                  испытания кабельно-проводниковой продукции (стойкость к внешним воздействующим
                  факторам, стойкость к механическим воздействиям, электрические испытания и
                  измерения), в т.ч. на соответствие требованиям ТР ТС 004/2011 «О безопасности
                  низковольтного оборудования»;<br><br>
                  анализ концентрации вредных веществ в воздухе рабочей зоны; <br><br>
                  замеры факторов производственной среды (относительная влажность, температура); замеры физических факторов (шум, освещенность).`,

      'Подробная информация об области аккредитации находится в реестре аккредитованных испытательных лабораторий': `Подробная информация об области аккредитации находится в реестре аккредитованных
              испытательных лабораторий на сайте Республиканского унитарного предприятия
              «Белорусский государственный центр аккредитации»:
              <p></p>
              <a href="https://bsca.by/ru/registry-testlab/view?id=1324" style={{color: '#E74137'}}>
                https://bsca.by/ru/registry-testlab/view?id=1324
              </a>
              <p></p>
              По вопросам проведения испытаний обращаться:
              <br>
              Начальник ЦЗЛ Шульга Алексей Васильевич
              <br>
              Тел.: +375 (236) 24-55-97; +375 (29) 305-06-77
              <br>
              Е-mail:
              <a href="mailto:czl@belaruskabel.by" style={{color: '#E74137'}}>
              czl@belaruskabel.by
              </a>`,
    },
  },
  en: {
    translation: {
      'Центральная заводская лаборатория ОАО «Беларускабель»': `The Central Plant Laboratory of JSC "Belaruskabel" (hereinafter CPL) complies with 
      criteria of the National Accreditation System of the Republic of Belarus and is accredited for compliance with the requirements 
      of GOST ISO/IEC 17025-2019 "General requirements for the competence of testing and calibration laboratories” 
      (accreditation certificate BY/112 2.0981 dated 05/28/1996, valid until 05.03.2027).
                  <p></p>
                  <span style={{textTransform: 'uppercase'}}>
                  Ample opportunities of our CPL allow to carry out the following types of testing:
                  </span>
                  <p></p>
                  testing of cable and wire products (resistance to external influences, factors, resistance to mechanical stress, electrical tests and measurements), 
                  incl. the compliance with the requirements of TR CU 004/2011 "On the safety of low-voltage equipment"; <br><br>
                  analysis of the concentration of harmful substances in the air of the working area; <br><br>
                  measurements of industrial environment factors (relative humidity, temperature); measurements of physical factors (noise, illumination).`,

      'Подробная информация об области аккредитации находится в реестре аккредитованных испытательных лабораторий': `Detailed information about the scope of 
      accreditation is in the register of accredited testing laboratories on the website of the 
      Republican Unitary Enterprise "Belarusian State Accreditation Center":
              <p></p>
              <a href="https://bsca.by/ru/registry-testlab/view?id=1324" style={{color: '#E74137'}}>
                https://bsca.by/ru/registry-testlab/view?id=1324
              </a>
              <p></p>
              For testing inquiries please contact:
              <p></p>
              Head of the Central Laboratory
              <p></p>
              Shulga Alexey Vasilyevich
              <p></p>
              Tel. +375 (236) 24-55-97; +375 (29) 305-06-77
              <p></p>
              E-mail:
              <a href="mailto:czl@belaruskabel.by" style={{color: '#E74137'}}>
                czl@belaruskabel.by
              </a>`,
    },
  },
  bel: {
    translation: {
      'Центральная заводская лаборатория ОАО «Беларускабель»': `Цэнтральная заводская лабараторыя ААТ "Беларускабель" (далей ЦЗЛ) адпавядае
                  крытэрыям Нацыянальнай сістэмы акрэдытацыі Рэспублікі Беларусь і акрэдытавана на
                  адпаведнасць патрабаванням ГОСТ ISO/IEC 17025-2019 «Агульныя патрабаванні да кампетэнтнасці
                  выпрабавальных і калібровачных лабараторый» (атэстат акрэдытацыі BY/112 2.0981 ад
                  28.05.1996, дзействаў. да 05.03.2027).
                  <p></p>
                  <span style={{textTransform: 'uppercase'}}>
                    У ВОБЛАСЦІ АКРЭДЫТАЦЫІ ЦЗЛ НАСТУПНЫЯ ВЫПРАБАВАННІ:
                  </span>
                  <p></p>
                  выпрабаванні кабельна-правадніковай прадукцыі (устойлівасць да вонкавых якія ўздзейнічаюць
                  фактараў, устойлівасць да механічных уздзеянняў, электрычныя выпрабаванні і
                  вымярэнні), у т.л. на адпаведнасць патрабаванням ТР МС 004/2011 «Аб бяспецы
                  нізкавольтнага абсталявання»; аналіз канцэнтрацыі шкодных рэчываў у паветры
                  працоўнай зоны; замеры фактараў вытворчага асяроддзя (адносная вільготнасць,
                  тэмпература); замеры фізічных фактараў (шум, асветленасць).`,

      'Подробная информация об области аккредитации находится в реестре аккредитованных испытательных лабораторий': `Падрабязная інфармацыя аб галіне акрэдытацыі знаходзіцца ў рэестры акрэдытаваных
              выпрабавальных лабараторый на сайце Рэспубліканскага унітарнага прадпрыемства
              "Беларускі дзяржаўны цэнтр акрэдытацыі":
              <p></p>
              <a href="https://bsca.by/be/registry-testlab/view?id=1324" style={{color: '#E74137'}}>
                https://bsca.by/ru/registry-testlab/view?id=1324
              </a>
              <p></p>
              Па пытаннях правядзення іспытаў звяртацца:
              <p></p>
              Начальнік ЦЗЛ Шульга Аляксей Васільевіч
              <p></p>
              Тэл. +375 (236) 24-55-97; +375 (29) 305-06-77
              <p></p>
              E-mail:
              <a href="mailto:czl@belaruskabel.by" style={{color: '#E74137'}}>
                czl@belaruskabel.by
              </a>`,
    },
  },
}
